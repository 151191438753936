import React from "react";
import About from "../../components/About/About";
import About2 from "../../components/About/About2";
import Banner from "../../components/Banner/Banner";
import BannerTwo from "../../components/Banner/BannerTwo";
import ContactTwo from "../../components/Contact/ContactTwo";
import CountDownTwo from "../../components/CountDown/CountDownTwo";
import CounterArea from "../../components/CounterArea/CounterArea";
import DownloadArea from "../../components/DownloadArea/DownloadArea";
import Faq from "../../components/Faq/Faq";
import Newsletter from "../../components/Newsletter/Newsletter";
import RoadmapTwo from "../../components/Roadmap/RoadmapTwo";
import TeamTwo from "../../components/Team/TeamTwo";
import WhyChooseUsTwo from "../../components/WhyChooseUs/WhyChooseUsTwo";
import WhoWeAre from "../../components/WhoWeAre/WhoWeAre";
import LayoutTwo from "../../layouts/LayoutTwo";
import LayoutOne from "../../layouts/LayoutOne";
import LayoutGivartz from "../../layouts/LayoutGivartz";
import videobg from "../../assets/videos/2.mp4"
import logoImage from "../../assets/img/logo/logo.png";
import { Link } from "react-router-dom";


const Priv = () => {
  return (
    <LayoutGivartz>
      <main className="fix" >
        {/* <BannerTwo /> */}

       {/* <WhoWeAre /> */}
        <section className="video-box-container-bg">
           <div className="rene-box">
                      <h1>
                      Privacy Policy
                      </h1>

              </div>

              

            <video 
              className="video-bg"
              autoPlay={true}
                loop={true} 
              playsInline={true}
              webkitplaysinline="true"
              tabIndex="-1" 
              aria-hidden="true"
             >
              <source  src={videobg} type="video/mp4"/>
                <source  src={videobg} type="video/webm"/>
              <source src={videobg} type="video/ogg"/>

            

              </video>




        
        </section>

        <section className="container-normal">
        <div className="blog-post-item blog-details-wrap">
      <div className="blog-post-thumb">
        {/* <img src={blogImg} alt="" /> */}
      </div>
      <div className="blog-post-content">
        
        <h2 className="title">
        Privacy Policy
        </h2>
      
        
        <div className="bd-approach-wrap">
          <div className="row align-items-center">
            <div className="">
              <div className="bd-approach-content">
                <h4 className="title">1. Introduction</h4>
                <p>
                At Givartz, we are committed to protecting the privacy and security of our users. 
                This privacy policy explains how we collect, 
                use, disclose, and protect your personal information when you visit our website Givartz.com (the "Site").
                </p>

              
              </div>
              <div className="bd-approach-content">
                <h4 className="title">2. Information We Collect</h4>
                <p>
                2.1. Personal Information: We may collect personal information that you provide to us directly, 
                such as your name, email address, phone number, postal address, and any other information you choose to provide.
                </p>
                <p>
                2.2. Browsing Information: We collect information about your activity on the Site, such as IP address, 
                browser type, pages visited, visit duration, and links clicked.
                </p>
                <p>
                2.3. Cookies and Similar Technologies: We use cookies and similar technologies to collect information about your use of the Site and to personalize your experience. 
                You can configure your browser to reject cookies, but this may affect your ability to use some features of the Site.
                </p>

              
              </div>
              <div className="bd-approach-content">
                <h4 className="title">3. Use of Information</h4>
                <p>
                We use the information we collect to:
                </p>
                <p>3.1. Provide and maintain the Site.</p>
                <p>3.2. Personalize and improve your experience on the Site.</p>
                <p>3.3. Send promotional and marketing communications, if you have consented to receive them.</p>
                <p>3.4. Respond to your inquiries and requests.</p>
                <p>3.5. Analyze the use of the Site and conduct research to improve our services.</p>

              
              </div>
              <div className="bd-approach-content">
                <h4 className="title">4. Disclosure of Information</h4>
                <p>
                We may disclose your information to third parties in the following circumstances:
                </p>
                <p>4.1. Service Providers: We may share information with third parties that help us operate the Site and provide our services.</p>
                <p>4.2. Legal Requirements: We may disclose information if required by law or if we believe in good faith that such action is necessary to comply with applicable laws or to protect and defend our rights or property.</p>
                <p>4.3. Business Transfers: In the event of a merger, acquisition, or sale of assets, your information may be transferred to the acquiring entity.</p>

              
              </div>
              <div className="bd-approach-content">
                <h4 className="title">5. Information Security</h4>
                <p>
                We take reasonable measures to protect personal information against loss, theft, misuse, and unauthorized access, disclosure, alteration, and destruction. However, 
                no data transmission over the Internet or storage system can be guaranteed to be 100% secure.
                </p>

              
              </div>
              <div className="bd-approach-content">
                <h4 className="title">6. Your Rights</h4>
                <p>
                Depending on your jurisdiction, you may have additional rights regarding your personal information, such as the right to access, correct, delete, 
                or restrict the use of your information. To exercise these rights, please contact us at givartz.com.
                </p>

              
              </div>
              <div className="bd-approach-content">
                <h4 className="title">7. Changes to this Privacy Policy</h4>
                <p>
                We may update this privacy policy from time to time. We will notify you about any changes by posting the new privacy policy on this page. 
                We recommend that you review this policy periodically to stay informed about how we protect your information.
                </p>

              
              </div>
              <div className="bd-approach-content">
                <h4 className="title">8. Contact</h4>
                <p>
                If you have any questions or concerns about this privacy policy, please contact us at:
                contact@givartz.com
                </p>

              
              </div>
              <div className="bd-approach-content">
                <h4 className="title">7. Contact Information</h4>
                <p>
                If you have any questions about these Terms and Conditions, please contact us at:
                contacta@givartz.com
                </p>

              
              </div>
            </div>

            <div className="">
              <div className="bd-approach-img">
                {/* <img src={blogDetails01} alt="" /> */}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="bd-technology-content">
          <h2 className="title">What Is A Business Technology Roadmap?</h2>
          <p>
            Unlike detailed blueprints that lay out all tasks, deadlines, bug
            reports, and more along the way, technology roadmaps are high-level
            visual summaries highlighting a company’s vision or plans.
          </p>
          <p>
            In an Agile approach, a technology roadmap feeds the sprint and
            grooming processes, providing insight into how the product will
            travel from start to finish. It makes it easier for development
            teams to:
          </p>
        </div> */}

        <div className="blog-details-img">
          <div className="row">
            <div className="col-md-6">
              {/* <img src={blogDetails02} alt="" /> */}
            </div>
            <div className="col-md-6">
              {/* <img src={blogDetails03} alt="" /> */}
            </div>
          </div>
        </div>

        
      </div>
    </div>


        </section>



       {/* <Banner /> */}
        {/* <About /> */}
        {/* <About2 /> */}
        {/* <WhyChooseUsTwo /> */}
        {/* <CounterArea /> */}
        {/* <RoadmapTwo /> */}
        {/* <TeamTwo /> */}
        {/* <DownloadArea /> */}
        {/* <Faq /> */}
        {/* <ContactTwo /> */}
        {/* <Newsletter /> */}
      </main>
    </LayoutGivartz>
  );
};

export default Priv;
