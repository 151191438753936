import React from "react";
import About from "../../components/About/About";
import About2 from "../../components/About/About2";
import Banner from "../../components/Banner/Banner";
import BannerTwo from "../../components/Banner/BannerTwo";
import ContactTwo from "../../components/Contact/ContactTwo";
import CountDownTwo from "../../components/CountDown/CountDownTwo";
import CounterArea from "../../components/CounterArea/CounterArea";
import DownloadArea from "../../components/DownloadArea/DownloadArea";
import Faq from "../../components/Faq/Faq";
import Newsletter from "../../components/Newsletter/Newsletter";
import RoadmapTwo from "../../components/Roadmap/RoadmapTwo";
import TeamTwo from "../../components/Team/TeamTwo";
import WhyChooseUsTwo from "../../components/WhyChooseUs/WhyChooseUsTwo";
import WhoWeAre from "../../components/WhoWeAre/WhoWeAre";
import LayoutTwo from "../../layouts/LayoutTwo";
import LayoutOne from "../../layouts/LayoutOne";
import LayoutGivartz from "../../layouts/LayoutGivartz";
import videobg from "../../assets/videos/2.mp4"
import logoImage from "../../assets/img/logo/logo.png";


const Path = () => {
  return (
    <LayoutGivartz>
      <main className="fix" >
    

       {/* <WhoWeAre /> */}
        <section className="video-box-container-bg">
           <div className="rene-box">
                      <img
                      className="logo-givartz-mod"
                      src={logoImage}
                      />

              </div>

              

            <video 
              className="video-bg"
              autoPlay={true}
                loop={true} 
              playsInline={true}
              webkitplaysinline="true"
              tabIndex="-1" 
              aria-hidden="true"
             >
              <source  src={videobg} type="video/mp4"/>
                <source  src={videobg} type="video/webm"/>
              <source src={videobg} type="video/ogg"/>

            

              </video>




        
        </section>
        
      
        <section id="aboutdos" className="about-area-two magiafondodos">
      <div className="container custom-container-four">
        <div className="row align-items-start">
        <div className="col-lg-4">
            <div
              className="about-content-two wow fadeInRight"
              data-wow-delay=".2s"
            >
              <div className="section-title ">
                <p>This is  Givartz </p>
                <h2 className="title">
                Partners for
                Evolution 
                </h2>
              </div>
              
            </div>
          </div>
         <section className="col-lg-8">


         <div className="col-lg-12">
            <div
             
            >
              <div className="section-title section-title-two mb-15">
                <h4 className="titledos">
                Future proof 
                organizations 
                </h4>
              </div>
              <p className="pointdos">
              We design a comprehensive strategic plan tailored to the current state of your brand, 
              ensuring it is adaptable to future challenges.
              </p>
            </div>



            <div
             
            >
              <div className="section-title section-title-two mb-15">
                <h4 className="titledos">
                Partner for 
                success
                </h4>
              </div>
              <p className="pointdos">
              We integrate our digital ecosystem with your 
              business needs to enhance your results. We are your central ally!
              </p>
            </div>


            <div
             
            >
              <div className="section-title section-title-two mb-15">
                <h4 className="titledos">
                Leading edge
                  
                technologies
                </h4>
              </div>
              <p className="pointdos">
              We align our digital ecosystem with your business needs 
              to boost your results. We are your main ally!
              </p>
            </div>


            <div
             
            >
              <div className="section-title section-title-two mb-15">
                <h4 className="titledos">
                Data-driven
                  
                decision making
                
                </h4>
              </div>
              <p className="pointdos">
              We analyze every touchpoint of your brand's journey in real time, identifying 
              opportunities that enhance the impact of your business decisions for the future.
              </p>
            </div>
          </div>


         </section>
        </div>
        
      </div>

      <div>
        
      </div>

    
    </section>

    
    <section id="aboutdos" className="about-area-two magiafondodos">
      <div className="container custom-container-four">
      <div className="row align-items-center  mb-15">
        <div className="col-lg-5">

          <h4>
          We are your partner in strategic digital innovation consulting.
          </h4>

        </div>
        <div className="col-lg-7">

          <p>
          We bring the future closer and connect it to the present of your brands to 
          achieve accelerated and sustainable growth in relation to the market.
          </p> 
          <p>
            
              Nuestra trayectoria, expertise e impacto en la Región está avalado por 
              grandes referentes en la industria:
          </p>
 
        </div>

        </div>

       
        
      </div>

      <div>
        
      </div>

    
    </section>



    
   
   
       
      </main>
    </LayoutGivartz>
  );
};

export default Path;
