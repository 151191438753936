import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home/Home";
import { useEffect } from "react";
import WOW from "wow.js";
import HomeTwo from "./pages/Home/HomeTwo";
import HomeGivart from "./pages/Home/HomeGivart";
import Servicios from "./pages/Home/Servicios";
import AboutG from "./pages/Home/AboutG"
import Path from "./pages/Home/Path"
import Blog from "./pages/Blog/Blog";
import BlogDetailsPage from "./pages/BlogDetails/BlogDetailsPage";
import { useLocation } from "react-router-dom";
import ContactOne from "./components/Contact/ContactOne";
import Terminos from "./pages/Home/Terminos";
import Priv from "./pages/Home/Priv"

function App() {
  useEffect(() => {
    const wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: false,
      live: true,
    });
    wow.init();
  }, []);

  const { pathname } = useLocation();
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [pathname]);
  
  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100); // 1000 milisegundos = 1 segundo
  
    return () => clearTimeout(timer); // Limpiar el timeout si el componente se desmonta o el pathname cambia
  }, [pathname]);

  return (
    <>
      <Routes>
        {/* <Route path="/papi" element={<Home />} /> */}
        <Route index element={<HomeGivart/>} />
        <Route path="/services" element={<Servicios/>} />

        
        <Route path="/partners" element={<Path/>} />        
        <Route path="/about" element={<AboutG/>} />        
        <Route path="/term" element={<Terminos />} />
        <Route path="/priv" element={<Priv />} />
        {/* <Route path="/home" element={<HomeTwo />} />
        <Route path="blog-details" element={<BlogDetailsPage />} /> */}
        <Route path="contact" element={<ContactOne/>} />
        <Route path="*" element={ <Navigate replace to="/" />} />
       
      </Routes>
    </>
  );
}

export default App;
