import React,{ useEffect} from "react";
import About from "../../components/About/About";
import About2 from "../../components/About/About2";
import Banner from "../../components/Banner/Banner";
import BannerTwo from "../../components/Banner/BannerTwo";
import ContactTwo from "../../components/Contact/ContactTwo";
import CountDownTwo from "../../components/CountDown/CountDownTwo";
import CounterArea from "../../components/CounterArea/CounterArea";
import DownloadArea from "../../components/DownloadArea/DownloadArea";
import Faq from "../../components/Faq/Faq";
import Newsletter from "../../components/Newsletter/Newsletter";
import RoadmapTwo from "../../components/Roadmap/RoadmapTwo";
import TeamTwo from "../../components/Team/TeamTwo";
import WhyChooseUsTwo from "../../components/WhyChooseUs/WhyChooseUsTwo";
import WhoWeAre from "../../components/WhoWeAre/WhoWeAre";
import LayoutTwo from "../../layouts/LayoutTwo";
import LayoutOne from "../../layouts/LayoutOne";
import LayoutGivartz from "../../layouts/LayoutGivartz";
import videobg from "../../assets/videos/2.mp4"
import logoImage from "../../assets/img/logo/logo.png";
import { Link } from "react-router-dom";


const Terminos = () => {
    // useEffect(() => {
    //     window.scrollTo(0, 0);
    //   }, []);
  return (
    <LayoutGivartz>
      <main className="fix" >
        {/* <BannerTwo /> */}

       {/* <WhoWeAre /> */}
        <section className="video-box-container-bg">
           <div className="rene-box">
                      <h1>
                      Terms and Conditions
                      </h1>

              </div>

              

            <video 
              className="video-bg"
              autoPlay={true}
                loop={true} 
              playsInline={true}
              webkitplaysinline="true"
              tabIndex="-1" 
              aria-hidden="true"
             >
              <source  src={videobg} type="video/mp4"/>
                <source  src={videobg} type="video/webm"/>
              <source src={videobg} type="video/ogg"/>

            

              </video>




        
        </section>

        <section className="container-normal">
        <div className="blog-post-item blog-details-wrap">
      <div className="blog-post-thumb">
        {/* <img src={blogImg} alt="" /> */}
      </div>
      <div className="blog-post-content">
        
        <h2 className="title">
        Terms and Conditions
        </h2>
      
        
        <div className="bd-approach-wrap">
          <div className="row align-items-center">
            <div className="">
              <div className="bd-approach-content">
                <h4 className="title">1. Acceptance of Terms</h4>
                <p>
                By accessing and using this website, you agree to comply with and 
                be bound by the following terms and conditions of use, which, together with our privacy policy, 
                govern the relationship between Givartz and you in relation to this website. 
                If you do not agree with any part of these terms and conditions, please do not use our website.
                </p>

              
              </div>
              <div className="bd-approach-content">
                <h4 className="title">2. Use of the Site</h4>
                <p>
                2.1. The content of the pages of this website is for your general 
                information and use only. It is subject to change without notice.
                </p>
                <p>
                2.2. This website uses cookies to monitor browsing preferences. If you do allow cookies 
                to be used, the following personal information may be stored by us.
                </p>
                <p>
                2.3. Neither we nor any third parties provide any warranty or guarantee as to the accuracy, 
                timeliness, performance, completeness, or suitability of the information and materials found 
                or offered on this website for any particular purpose. You acknowledge that such information 
                and materials may contain inaccuracies or errors, 
                and we expressly exclude liability for any such 
                inaccuracies or errors to the fullest extent permitted by law.
                </p>

              
              </div>
              <div className="bd-approach-content">
                <h4 className="title">3. Links to Other Websites</h4>
                <p>
                This website may include links to other websites. 
                These links are provided for your convenience to provide further information. 
                They do not signify that we endorse the website(s). We have no responsibility 
                for the content of the linked website(s).
                </p>

              
              </div>
              <div className="bd-approach-content">
                <h4 className="title">4. Limitation of Liability</h4>
                <p>
                In no event will we be liable for any loss or damage including, without limitation, 
                indirect or consequential losses or damages, 
                or any loss or damage whatsoever arising from the use or in connection with the use of this website.
                </p>

              
              </div>
              <div className="bd-approach-content">
                <h4 className="title">5. Changes to the Terms</h4>
                <p>
                We may revise these terms and conditions at any time by updating this posting. You should visit this 
                page from time to time to review the current terms and conditions because they are binding on you.
                </p>

              
              </div>
              <div className="bd-approach-content">
                <h4 className="title">6. Governing Law</h4>
                <p>
                Your use of this website and any dispute arising out of 
                such use of the website is subject to the laws of Panama.
                </p>

              
              </div>
              <div className="bd-approach-content">
                <h4 className="title">7. Contact Information</h4>
                <p>
                If you have any questions about these Terms and Conditions, please contact us at:
                contacta@givartz.com
                </p>

              
              </div>
            </div>

            <div className="">
              <div className="bd-approach-img">
                {/* <img src={blogDetails01} alt="" /> */}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="bd-technology-content">
          <h2 className="title">What Is A Business Technology Roadmap?</h2>
          <p>
            Unlike detailed blueprints that lay out all tasks, deadlines, bug
            reports, and more along the way, technology roadmaps are high-level
            visual summaries highlighting a company’s vision or plans.
          </p>
          <p>
            In an Agile approach, a technology roadmap feeds the sprint and
            grooming processes, providing insight into how the product will
            travel from start to finish. It makes it easier for development
            teams to:
          </p>
        </div> */}

        <div className="blog-details-img">
          <div className="row">
            <div className="col-md-6">
              {/* <img src={blogDetails02} alt="" /> */}
            </div>
            <div className="col-md-6">
              {/* <img src={blogDetails03} alt="" /> */}
            </div>
          </div>
        </div>

        
      </div>
    </div>


        </section>



       {/* <Banner /> */}
        {/* <About /> */}
        {/* <About2 /> */}
        {/* <WhyChooseUsTwo /> */}
        {/* <CounterArea /> */}
        {/* <RoadmapTwo /> */}
        {/* <TeamTwo /> */}
        {/* <DownloadArea /> */}
        {/* <Faq /> */}
        {/* <ContactTwo /> */}
        {/* <Newsletter /> */}
      </main>
    </LayoutGivartz>
  );
};

export default Terminos;
