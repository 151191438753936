import React from "react";
import { Link } from "react-router-dom";
import logoImage from "../../../src/assets/img/logo/logo.png";
import { scrollToTop } from "../../lib/helpers";
import videobg from "../../assets/videos/2.mp4"

const FooterOne = () => {
  return (
    <footer className="background-footer">
        <video 
              className="video-bg-pro"
              autoPlay={true}
                loop={true} 
              playsInline={true}
              webkitplaysinline="true"
              tabIndex="-1" 
              aria-hidden="true"
             >
              <source  src={videobg} type="video/mp4"/>
                <source  src={videobg} type="video/webm"/>
              <source src={videobg} type="video/ogg"/>

            

              </video>


      <div className=" bg-black-givarts">
        <div className="container">
          <div className="footer-scroll-wrap">
            <button
              className="scroll-to-target"
              data-target="html"
              onClick={scrollToTop}
            >
              <i className="fas fa-arrow-up"></i>
            </button>
          </div>
          <div className="footer-top">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <Link to="/" className="f-logo">
                    <img src={logoImage} alt="BigTech Logo" />
                  </Link>
                  <div className="footer-content">
                    <p>
                    In a rapidly evolving digital landscape, we stand as your trusted partner, offering cutting-edge technology and creative solutions that adapt to your needs.
                    </p>
                    <ul className="footer-social">
                      {/* <li>
                        <a href="#">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li> */}
                      {/* <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li> */}
                      <li>
                        <a href="https://www.facebook.com/givartz">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/givartz">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-5 col-sm-6">
                <div
                  className="footer-widget  wow fadeInUp"
                  data-wow-delay=".4s"
                >
                  <h4 className="fw-title">Useful Links</h4>
                  <div className="footer-link">
                    <ul>
                      <li>
                        <Link to="/contact">Contact us</Link>
                      </li>
                      <li>
                        <Link to="/services">Services</Link>
                      </li>
                      <li>
                        <Link to="/">Support</Link>
                      </li>
                      <li>
                        <Link to="/priv">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/term">Terms and Conditions</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-sm-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".6s"
                >
                  <h4 className="fw-title">Community</h4>
                  <div className="footer-link">
                    <ul>
                      <li>
                        <Link to="/">Help Center</Link>
                      </li>
                      <li>
                        <Link to="/partners">Partners</Link>
                      </li>
                      <li>
                        <Link to="/">Suggestions</Link>
                      </li>
                      <li>
                        <Link to="/">Blog</Link>
                      </li>
                      <li>
                        <Link to="/">Newsletters</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".8s"
                >
                  <h4 className="fw-title">Subscribe Newsletter</h4>
                  <div className="footer-newsletter">
                    <p>
                    Ready to stay in the loop with the latest in mobile innovation? Subscribe to Givartz and embark on a journey of cutting-edge app development. 
                    </p>
                    <form action="#">
                      <input
                        type="email"
                        placeholder="Contact@Givartz.com"
                        required
                      />
                      <button type="submit">
                        <i className="fas fa-paper-plane"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="copyright-text">
                  <p>Copyright &copy; 2017. All Rights Reserved Givartz</p>
                </div>
              </div>
              <div className="col-lg-6 d-none d-sm-block">
                <div className="footer-menu">
                  <ul>
                    <li>
                      <Link to="/term">Terms and conditions</Link>
                    </li>
                    <li>
                      <Link to="/priv">Privacy policy</Link>
                    </li>
                    <li>
                      <Link to="#">Login / Signup</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
