import React from "react";
import ContactOneForm from "./ContactOneForm";
import ContactOneInfoItem from "./ContactOneInfoItem";
import FooterOne from "../../components/Footer/FooterOne";
import logoImage from "../../../src/assets/img/logo/logo.png";
import { Link, NavLink, useLocation } from "react-router-dom";

const ContactOne = () => {
  const info_items = [
    {
      icon: "fa-envelope",
      content: (
        <>
          contact@givartz.com <br /> info@givartz.com
        </>
      ),
    },
    {
      icon: "fa-phone",
      content: (
        <>
          +507 6038 - 9622
          <br /> 
        </>
      ),
    },
    {
      icon: "fa-map-marker-alt",
      content: (
        <>
          {/* State/province/area: <br /> */}
          Panama / Panama / Panama City
        </>
      ),
    },
  ];

  return (

    <>
    
    <div className="container custom-container-three">
          <div className="row">
            <div className="col-12">
             
              <div className="menu-wrap">
                <nav className="menu-nav">
                  <div className="logo">
                    <Link to="/">
                      
                      <img src={logoImage} alt="" />
                    </Link>
                    <p className="logo-leyenda">
                      GIVARTZ
                    </p>
                  </div>
                

                </nav>
              </div>

              {/* <!-- Mobile Menu  --> */}
              <div className="mobile-menu">
                <nav className="menu-box">
                  <div className="close-btn">
                    <i className="fas fa-times"></i>
                  </div>
                    <Link to="/">
                  <div className="nav-logo">
                      <img src={logoImage} alt="" title="" />
                  </div>
                    </Link>
                
                  
                </nav>

              </div>
         
              {/* <!-- End Mobile Menu --> */}
            </div>
          </div>
        </div>
    
    <section id="contact" className="contact-area pt-70 pb-110">
      <div className="container">
        {/* section title */}
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title text-center mb-70">
              <span className="sub-title">Contact</span>
              <h2 className="title">
                <span>Contact</span> Givartz
              </h2>
            </div>
          </div>
        </div>

        {/* secction info */}
        <div className="contact-info-wrap">
          <div className="row justify-content-center">
            {info_items.map((x, index) => (
              <div key={index} className="col-lg-4 col-sm-6">
                <ContactOneInfoItem item={x} />
              </div>
            ))}
          </div>
        </div>

        {/* section form */}
        <ContactOneForm />
      </div>
    </section>
    <FooterOne />
    </>
    
  );
};

export default ContactOne;
