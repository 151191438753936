import React from "react";
import About from "../../components/About/About";
import About2 from "../../components/About/About2";
import Banner from "../../components/Banner/Banner";
import BannerTwo from "../../components/Banner/BannerTwo";
import ContactTwo from "../../components/Contact/ContactTwo";
import CountDownTwo from "../../components/CountDown/CountDownTwo";
import CounterArea from "../../components/CounterArea/CounterArea";
import DownloadArea from "../../components/DownloadArea/DownloadArea";
import Faq from "../../components/Faq/Faq";
import Newsletter from "../../components/Newsletter/Newsletter";
import RoadmapTwo from "../../components/Roadmap/RoadmapTwo";
import TeamTwo from "../../components/Team/TeamTwo";
import WhyChooseUsTwo from "../../components/WhyChooseUs/WhyChooseUsTwo";
import WhoWeAre from "../../components/WhoWeAre/WhoWeAre";
import LayoutTwo from "../../layouts/LayoutTwo";
import LayoutOne from "../../layouts/LayoutOne";
import LayoutGivartz from "../../layouts/LayoutGivartz";
import videobg from "../../assets/videos/2.mp4"
import logoImage from "../../assets/img/logo/logo.png";


const AboutG = () => {
  return (
    <LayoutGivartz>
      <main className="fix" >
    

       {/* <WhoWeAre /> */}
        <section className="video-box-container-bg">
           <div className="rene-box">
                      <img
                      className="logo-givartz-mod"
                      src={logoImage}
                      />

              </div>

              

            <video 
              className="video-bg"
              autoPlay={true}
                loop={true} 
              playsInline={true}
              webkitplaysinline="true"
              tabIndex="-1" 
              aria-hidden="true"
             >
              <source  src={videobg} type="video/mp4"/>
                <source  src={videobg} type="video/webm"/>
              <source src={videobg} type="video/ogg"/>

            

              </video>




        
        </section>
        
      
        <section id="aboutdos" className="about-area-two magiafondodos">
      <div className="container custom-container-four">
        <div className="row align-items-center">
          <div className="col-lg-6">
           
          </div>
          <div className="col-lg-6">
            <div
              className="about-content-two wow fadeInRight"
              data-wow-delay=".2s"
            >
              <div className="section-title section-title-two mb-15">
                <span className="sub-title">About Givartz </span>
                <h2 className="title">
                Vision Statement
                </h2>
              </div>
              <p>
              Founded in 2017, Givartz aims to be a global leader in 
              transforming businesses through innovative software development and strategic marketing solutions. We envision a world where our creative technology and advanced marketing strategies drive unparalleled growth and success for our clients in the digital landscape. 🚀


              </p>
            </div>
          </div>
        </div>
      </div>

    
    </section>
    <section id="about" className="about-area-two">
      <div className="container custom-container-four">
        <div className="row align-items-center">
        <div className="col-lg-6">
            <div
              className="about-content-two wow fadeInRight"
              data-wow-delay=".2s"
            >
              <div className="section-title section-title-two mb-15">
              <span className="sub-title">About Givartz </span>
                <h2 className="title">
                Mission Statement
                </h2>
              </div>
              <p>
              Since our inception in 2017, Givartz is committed to 
              delivering exceptional software development and marketing 
              services that enhance our clients' digital presence and optimize their operations. We aim to provide cutting-edge solutions, leveraging our expertise to help businesses achieve their goals and thrive in a competitive market.


              </p>
           
              
            </div>
          </div>
       
          
        </div>
      </div>

 
    </section>
       
      </main>
    </LayoutGivartz>
  );
};

export default AboutG;
