import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./PageLoader.module.css";
import Logo from '../../assets/img/logo/LogoG.svg'
const PageLoader = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 2000));
      setLoading((loading) => !loading);
    };

    loadData();
  }, []);

  if (loading) {
    return (
  

<div className="logo-box-givartz-loader" >
  <div className="container-logo-givartz">
  <img alt="logo-givartz" 
      className="logo-size-givartz"
      src={Logo}
  /> 

  <section className="bar-bar-givartz">
  <span>
    
  </span>

  </section>
  </div>

</div>
    );
  } else {
    return null;
  }
};

export default PageLoader;
