import React, { useState } from "react";

const ContactOneForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSending(true);

    // Datos a enviar al servidor
    const formData = {
      name: name,
      email: email,
      message: message
    };

    // Hacer la solicitud a la API
    fetch('https://givartz.com/webservices/mail.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    .then(response => response.json())
    .then(data => {
      console.log('Respuesta del servidor:', data);
      setSending(false);
    })
    .catch(error => {
      console.error('Error al enviar la solicitud:', error);
      setSending(false);
    });
  };

  return (
    <div className="contact-form-wrap">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-grp">
              <input type="text" placeholder="Enter your Name" value={name} onChange={handleNameChange} required />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-grp">
              <input type="email" placeholder="Enter you email" value={email} onChange={handleEmailChange} required />
            </div>
          </div>
        </div>
        <div className="form-grp">
          <textarea name="message" placeholder="Enter your message" value={message} onChange={handleMessageChange}></textarea>
        </div>
        <div className="submit-btn text-center">
          <button type="submit" className="btn" disabled={sending}>
            {sending ? "Sending..." : "Send Message"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactOneForm;
