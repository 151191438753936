
import React from "react";
import PageLoader from "../components/PageLoader/PageLoader";
import cn from "classnames";
import HeaderTwo from "../components/Header/HeaderTwo";
import FooterTwo from "../components/Footer/FooterTwo";
import FooterOne from "../components/Footer/FooterOne";
import HeaderGivartz from "../components/Header/HeaderGivartzService";
const LayoutGivartz = (props) => {
  return (
    <div className={cn("white-background")}>
      <PageLoader />

      <HeaderGivartz/>

      {props.children}

      <FooterOne />
    </div>
  );
};

export default LayoutGivartz;
