import React from "react";
import img01z from "../../assets/img/team/h2_team_img01.png";
import img02z from "../../assets/img/team/h2_team_img02.png";
import img03z from "../../assets/img/team/h2_team_img03.png";
import img04z  from "../../assets/img/team/h2_team_img04.png";
import img01 from "../../assets/img/partner/partner_img01.png";
import img02 from "../../assets/img/partner/partner_img02.png";
import img03 from "../../assets/img/partner/partner_img03.png";
import img04 from "../../assets/img/partner/partner_img04.png";
import img05 from "../../assets/img/partner/partner_img05.png";
import img06 from "../../assets/img/partner/partner_img06.png";
import img07 from "../../assets/img/partner/partner_img07.png";
import img08 from "../../assets/img/partner/partner_img08.png";
import img09 from "../../assets/img/partner/partner_img09.png";
import img10 from "../../assets/img/partner/partner_img10.png";

import one from '../../assets/img/logo/clientes/1.png'
import dos from '../../assets/img/logo/clientes/2.png'
import tres from '../../assets/img/logo/clientes/3.png'
import cuatro from '../../assets/img/logo/clientes/4.png'
import cinco from '../../assets/img/logo/clientes/5.png'
import seis from '../../assets/img/logo/clientes/6.png'
import TeamTwoItem from "./TeamTwoItem";

const TeamTwo = () => {
  const partners_list = [
    { src: img01, title: "" },
    { src: img02, title: "" },
    { src: img03, title: "" },
    { src: img04, title: "" },
    { src: img05, title: "" },
    { src: img06, title: "" },
    { src: img07, title: "" },
    { src: img08, title: "" },
    { src: img09, title: "" },
    { src: img10, title: "" },
  ];
  const team_members = [
    {
      src: one,
      name: "Cameron Williamson",
      designation: "Founder & CO",
    },
    {
      src: dos,
      name: "Eleanor Pena",
      designation: "Head of Design",
    },
    {
      src: tres,
      name: "Bessie Cooper",
      designation: "Vp People",
    },
    {
      src: cuatro,
      name: "Darlene Robertson",
      designation: "Product Manager",
    },
  ];

  return (
    <section className="team-area-two team-bg">
      <div className="container custom-container-four">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title text-center mb-60">
              <span className="sub-title">Special Toolkit</span>
              <h2 className="title">
                Parthner Tools
                 <br />
                <span>Software </span>
              </h2>
            </div>
          </div>
        </div>

        <div className="row justify-content-center d-none">
          {team_members.map((x, index) => (
            <div key={index} className="col-lg-3 col-md-4 col-sm-6">
              <TeamTwoItem item={x} />
            </div>
          ))}
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="partner-wrap">
              <ul>
                {partners_list.map((item, index) => (
                  <li key={index}>
                    <img src={item.src} alt={item.title} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamTwo;
