import React from "react";
import About from "../../components/About/About";
import Banner from "../../components/Banner/Banner";
import BannerTwo from "../../components/Banner/BannerTwo";
import ContactTwo from "../../components/Contact/ContactTwo";
import CountDownTwo from "../../components/CountDown/CountDownTwo";
import CounterArea from "../../components/CounterArea/CounterArea";
import DownloadArea from "../../components/DownloadArea/DownloadArea";
import Faq from "../../components/Faq/Faq";
import Newsletter from "../../components/Newsletter/Newsletter";
import RoadmapTwo from "../../components/Roadmap/RoadmapTwo";
import TeamTwo from "../../components/Team/TeamTwo";
import WhyChooseUsTwo from "../../components/WhyChooseUs/WhyChooseUsTwo";
import WhoWeAre from "../../components/WhoWeAre/WhoWeAre";
import LayoutTwo from "../../layouts/LayoutTwo";
import LayoutOne from "../../layouts/LayoutOne";
import LayoutGivartz from "../../layouts/LayoutGivartzService";
import videobg from "../../assets/videos/1.mp4"
import videohead from "../../assets/videos/3.mp4"
import videobody from "../../assets/videos/bg.mp4"
import logoImage from "../../assets/img/logo/logo.png";
import videorea from "../../assets/videos/9.mp4"
import videodev from "../../assets/videos/5.mp4"
import mrkimg from '../../assets/img/images/htts.png'
import mrkimgdos from '../../assets/img/images/htts2.png' 
import cuatro from '../../assets/img/images/444.png'



const Servicios = () => {
  return (
    <LayoutGivartz>
      <main className="fix" >
       {/* <WhoWeAre /> */}
       <section className="rene-sensa">
       <section className="video-rene">
{/* <video
className="video-bg"
  src={videobg}
  autoPlay="true"
  loop="true"
  
>
</video>  */}
<div className="flex-rene">
        <div className="Text-Big">
          <h1>
          We create <br/>
digital <br/>
solutions
          </h1>
        </div>
  </div>
  

  

<video 
  className="video-bg-rene"
  autoPlay={true}
                loop={true} 
              playsInline={true}
              webkitplaysinline="true"
              tabIndex="-1" 
              aria-hidden="true"
 >
  <source  src={videobg} type="video/mp4"/>
    <source  src={videobg} type="video/webm"/>
  <source src={videobg} type="video/ogg"/>



  </video>





          </section>

       </section>

      

       <section className="rene-sensa-04">

        <div className="box-rene-design">
        <div className="c">

<div className="">
<h2 className="text-arena-rene">
          As
          a
          tight-knit
          team
          of
          experts,
          we
          create
          memorable
          and
          emotional
          websites,
          digital
          experiences,
          and
          native
          apps.
          </h2>

</div>
        

          <div>
          <div className="">

          
<video 
  className="video-bg-brutal"
  autoPlay={true}
  loop={true} 
playsInline={true}
webkitplaysinline="true"
tabIndex="-1" 
aria-hidden="true"
>
  <source  src={videohead} type="video/mp4"/>
    <source  src={videohead} type="video/webm"/>
  <source src={videohead} type="video/ogg"/>



  </video>
</div>
          </div>

         

            

            

          </div>
        </div>

       </section>

       <section className="rene-sensa-03">

        <div className="box-rene-design">
        <h2 className="text-arena-rene-black ">
          Creative 
          
          
          <br/>
          Design
          </h2>

          <div>
          <div className="Box-round-rene">

          
<video 
  className="video-bg-brutal"
  autoPlay={true}
                loop={true} 
              playsInline={true}
              webkitplaysinline="true"
              tabIndex="-1" 
              aria-hidden="true"

>
  <source  src={videorea} type="video/mp4"/>
    <source  src={videorea} type="video/webm"/>
  <source src={videorea} type="video/ogg"/>



  </video>
</div>
          </div>
          <section className="marketing-main-black">
                                        <img
                                          alt="imagen-marketingdos"
                                          src={cuatro}
                                          className="imagen-marketingdos"
                                        />
                    </section>

          <div className="box-arena-rene-hijo">

            <div className="">
              <h3 className="text-arena-rene-black-lil-4">CREATIVE DESIGN</h3>

            </div>

            

            <div className="">
              <h4 className="text-arena-rene-black-lil-3">
                We
                don't
                do
                cookie-cutter
                solutions.
                Our
                user-centered
                design
                encourages
                productivity
                and
                boosts
                revenue.
              </h4>

            </div>

            

          </div>
        </div>

       </section>

        <section className="rene-sensa-04">

        <div className="box-rene-design">
        <h2 className="text-arena-rene-white ">
        Smart

          
          
          <br/>
      
Development
          </h2>

          <div>
          <div className="Box-round-rene">

          
<video 
  className="video-bg-brutal-cuadrado"
  autoPlay={true}
                loop={true} 
              playsInline={true}
              webkitplaysinline="true"
              tabIndex="-1" 
              aria-hidden="true"

>
   <source  src={videodev} type="video/mp4"/>
    <source  src={videodev} type="video/webm"/>
  <source src={videodev} type="video/ogg"/>



  </video>
</div>
          </div>

          <div className="box-arena-rene-hijo">

            <div className="">
              <h3 className="text-arena-rene-black-lil-4-white">Smart
Development</h3>

            </div>

            

            <div className="">
              <h4 className="text-arena-rene-black-lil-3-white">
              Combining
              unique
              design
              and
              rich
              technology,
              we
              build
              digital
              products
              exactly
              as
              they
              were
              designed,
              without
              shortcuts
              or
              simplifications.
              </h4>

            </div>

            

          </div>
        </div>

       </section>
       
       <section className="marketing-main-black">
                                        <img
                                          alt="imagen-marketingdos"
                                          src={mrkimgdos}
                                          className="imagen-marketingdos"
                                        />
                    </section>

       
       <section className="rene-sensa">
   

                        <section className="rene-sensa">
                                <section className="video-rene-clean">
                                      {/* <video
                                      className="video-bg"
                                        src={videobg}
                                        autoPlay="true"
                                        loop="true"
                                        
                                      >
                                      </video>  */}
                                    

                                        <div className="flex-rene">
                                                <h2 className="text-arena-rene-black ">
                                                  Brand

                                                            
                                                            
                                                            <br/>
                                                          
                                                  identities
                                                            </h2>

                                                            <img
                                          alt="imagen-marketing"
                                          src={mrkimg}
                                          className="imagen-marketing"
                                        />

                                                <div className="box-arena-rene-hijo">

                                                  

                                                <div className="">
                                                  <h3 className="text-arena-rene-black-lil-4">CREATIVE DESIGN</h3>

                                                </div>



                                                <div className="">
                                                  <h4 className="text-arena-rene-black-lil-3">
                                                    We
                                                    don't
                                                    do
                                                    cookie-cutter
                                                    solutions.
                                                    Our
                                                    user-centered
                                                    design
                                                    encourages
                                                    productivity
                                                    and
                                                    boosts
                                                    revenue.
                                                  </h4>

                                                </div>



                                                </div>


                                                            

                                                      </div>
                
                                        

                                        

                                        <video 
                className="video-bg-rene"
                autoPlay={true}
                loop={true} 
              playsInline={true}
              webkitplaysinline="true"
              tabIndex="-1" 
              aria-hidden="true"
              
              >
                <source  src={videobody} type="video/mp4"/>
                  <source  src={videobody} type="video/webm"/>
                <source src={videobody} type="video/ogg"/>



                </video>


                                                </section>

                                            </section>

                    </section>


              


           
 
     
        
       {/* <Banner />
        <About /> */}
        {/* <WhyChooseUsTwo /> */}
        {/* <CounterArea /> */}
        {/* <RoadmapTwo />
        <TeamTwo />
        <DownloadArea /> */}
        {/* <Faq /> */}
        {/* <ContactTwo /> */}
        {/* <Newsletter /> */}
      </main>
    </LayoutGivartz>
  );
};

export default Servicios;
