import React from "react";
import About from "../../components/About/About";
import About2 from "../../components/About/About2";
import Banner from "../../components/Banner/Banner";
import BannerTwo from "../../components/Banner/BannerTwo";
import ContactTwo from "../../components/Contact/ContactTwo";
import CountDownTwo from "../../components/CountDown/CountDownTwo";
import CounterArea from "../../components/CounterArea/CounterArea";
import DownloadArea from "../../components/DownloadArea/DownloadArea";
import Faq from "../../components/Faq/Faq";
import Newsletter from "../../components/Newsletter/Newsletter";
import RoadmapTwo from "../../components/Roadmap/RoadmapTwo";
import TeamTwo from "../../components/Team/TeamTwo";
import WhyChooseUsTwo from "../../components/WhyChooseUs/WhyChooseUsTwo";
import WhoWeAre from "../../components/WhoWeAre/WhoWeAre";
import LayoutTwo from "../../layouts/LayoutTwo";
import LayoutOne from "../../layouts/LayoutOne";
import LayoutGivartz from "../../layouts/LayoutGivartz";
import videobg from "../../assets/videos/1.mp4"
import logoImage from "../../assets/img/logo/logo.png";


const HomeGivart = () => {
  return (
    <LayoutGivartz>
      <main className="fix" >
        <BannerTwo />

       {/* <WhoWeAre /> */}
        <section className="video-box-container-bg">
           <div className="rene-box">
                      <img
                      className="logo-givartz-mod"
                      src={logoImage}
                      />

              </div>

              

            <video 
              className="video-bg"
              autoPlay={true}
                loop={true} 
              playsInline={true}
              webkitplaysinline="true"
              tabIndex="-1" 
              aria-hidden="true"
             >
              <source  src={videobg} type="video/mp4"/>
                <source  src={videobg} type="video/webm"/>
              <source src={videobg} type="video/ogg"/>

            

              </video>




        
        </section>
        
       <Banner />
        <About />
        <About2 />
        <WhyChooseUsTwo />
        {/* <CounterArea /> */}
        <RoadmapTwo />
        <TeamTwo />
        <DownloadArea />
        {/* <Faq /> */}
        {/* <ContactTwo /> */}
        {/* <Newsletter /> */}
      </main>
    </LayoutGivartz>
  );
};

export default HomeGivart;
