import React from "react";
import RoadmapTwoItem from "./RoadmapTwoItem";

const RoadmapTwo = () => {
  const roadmap_items = [
    {
      roadmapTitle: "Services",
      title: "Business Management Mobile App",
      info: <> Simplify your operations with a custom app that covers everything from inventory management to sales tracking, all in the palm of your hand.</>,
    },
    {
      roadmapTitle: "Services",
      title: "Scalable E-commerce Platform",
      info: (
        <>
          {" "}
          Take your brick-and-mortar store online with an e-commerce platform designed to grow with you, featuring shopping carts, secure payment options, and order tracking
        </>
      ),
    },
    {
      roadmapTitle: "Services",
      title: "Health and Wellness App",
      info: (
        <>
          Help users lead a healthy lifestyle with an app that tracks physical activity, offers workout plans, and provides personalized nutrition tips.
        </>
      ),
    },
    {
      roadmapTitle: "Services",
      title: "Online Booking System",
      info: <>If you have an appointment-based business like a beauty salon or restaurant, an online booking system makes scheduling appointments convenient for your customers.</>,
    },
  ];

  const roadmap_items_2 = [
    {
      roadmapTitle: "Services",
      title: "Online Learning Platform:",
      info: <> Launch online courses with a learning platform that enables instructors to upload content, engage with students, and conduct assessments.</>,
    },
    {
      roadmapTitle: "Services",
      title: "Travel and Tourism App",
      info: (
        <>
          {" "}
          Assist travelers in planning their next adventure with an app that offers destination information, flight and hotel bookings, and itinerary recommendations.
        </>
      ),
    },
    {
      roadmapTitle: "Services",
      title: "Business Data Analysis Tool",
      info: (
        <>
          Provide businesses with a competitive edge using a tool that collects, analyzes, and visualizes key data for informed decision-making.
        </>
      ),
    },
    {
      roadmapTitle: "Services",
      title: "Niche-Specific Social Media Platform",
      info: <>Create a social network focused on a specific niche, where users can connect, share content, and engage in relevant discussions.</>,
    },
  ];

  return (
    <section id="roadmap" className="roadmap-area-two pt-130 pb-100">
      <div className="container custom-container-two">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title section-title-two text-center mb-65">
              <span className="sub-title">Our Givartz Services</span>
              <h2 className="title">
                Strategy and <br />
                Development Plan
              </h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="roadmap-wrap-two">
              {roadmap_items.map((x, index) => (
                <RoadmapTwoItem key={index} item={x} />
              ))}
            </div>

            <div className="roadmap-wrap-two bottom">
              {roadmap_items_2.map((x, index) => (
                <RoadmapTwoItem key={index} item={x} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RoadmapTwo;
